import "$lib/supabaseClient"

import * as SentrySvelte from "@sentry/svelte"
import { BrowserTracing } from "@sentry/tracing"
import type { HandleClientError } from "@sveltejs/kit"

SentrySvelte.init({
    dsn: "https://bfa486b1e220444f98391d11c630586e@o4504731352694784.ingest.sentry.io/4504731355119616",
    integrations: [new BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
})

export const handleError: HandleClientError = ({ error, event }) => {
	const errorId = crypto.randomUUID()
	SentrySvelte.captureException(error, {
		contexts: { sveltekit: { event, errorId } },
	})

	return {
		message: "An unexpected error occurred. Please try again later.",
		errorId,
	}
}